<template>
  <div class="">
    <el-form class="form-inline" :inline="true" size="small">
      <el-form-item label="门店">
        <el-select v-model="search.shop_id" placeholder="请选择">
          <el-option :key="0" label="总部" :value="0"></el-option>
          <el-option
              v-for="item in shopList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="goSearch">搜索</el-button>
        <el-button type="warning" size="small" @click="showBox(false)" v-if="search.shop_id==0">添加</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="progressList"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini">
      <el-table-column
          prop="price"
          label="进度"
          align="center"
          width="300">
        <template slot-scope="scope">
          <el-progress type="circle" :percentage="scope.row.lv>100 ? 100 : scope.row.lv" :width="80" color="#d94e5d" v-if="scope.row" :format="getLvFormat(scope.row.lv)"></el-progress>
        </template>
      </el-table-column>
      <el-table-column
          prop="title"
          label="进度标题"
          align="center">
      </el-table-column>
      <el-table-column
          prop="type_str"
          label="类型"
          align="center">
      </el-table-column>
      <el-table-column
          prop="start_time"
          label="进度时间段"
          align="center">
        <template slot-scope="scope">
          {{ scope.row.start_time }} ~ {{ scope.row.end_time }}
        </template>
      </el-table-column>
      <el-table-column
          prop="imgs"
          label="进度目标"
          align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.type==1 || scope.row.type==2 || scope.row.type==4">{{ scope.row.amount }}元</div>
          <div v-else>{{ scope.row.sum }}件</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="finish"
          label="已完成"
          align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.type==1 || scope.row.type==2 || scope.row.type==4">{{ scope.row.finish }}元</div>
          <div v-else>{{ scope.row.finish }}件</div>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="150">
        <template slot-scope="scope">
          <div v-if="scope.row.store_id==0">
            <el-button type="text" size="small" @click="showBox(scope.row)">编辑</el-button>
            <el-popconfirm
               class="mg-lf-10"
               title="确定要删除这个吗？"
               @confirm="delBox(scope.row)"
            >
              <el-button type="text" size="small" slot="reference">删除</el-button>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="编辑" :visible.sync="dialogVisible" custom-class="dialog" width="700px">
      <el-form :model="form" size="small" :rules="rules" ref="ruleForm">
        <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
          <el-input v-model="form.title" autocomplete="off" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="时间" :label-width="formLabelWidth">
          <el-date-picker
              :clearable="false"
              size="small"
              v-model="dateRange"
              type="daterange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="类型" :label-width="formLabelWidth">
          <el-radio-group v-model="form.type">
            <el-radio :label="1">总订货金额</el-radio>
            <el-radio :label="2">改件订货额</el-radio>
            <el-radio :label="3">改件数量</el-radio>
            <el-radio :label="4">膜类订货额</el-radio>
            <el-radio :label="5">膜类数量</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="金额" :label-width="formLabelWidth" v-if="form.type==1 || form.type==2 || form.type==4" prop="amount">
          <el-input v-model="form.amount" autocomplete="off" placeholder="请输入金额"></el-input>
        </el-form-item>
        <el-form-item label="数量" :label-width="formLabelWidth" prop="sum" v-else>
          <el-input v-model="form.sum" autocomplete="off" placeholder="请输入数量"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveBox" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import dayjs from "dayjs";

  export default {
    data() {
      return {
        dialogVisible: false,
        formLabelWidth: '60px',
        source_domain: this.config.SOURCE_DOMAIN,
        shopList: [],
        progressList: [],
        search: {
          shop_id: 0
        },
        dateRange: ['',''],
        form: {
          title: '',
          start_time: '',
          end_time: '',
          type: 1,
          amount: '',
          sum: ''
        },
        rules: {
          title: [
            {required: true, message: '请输入标题', trigger: 'blur'}
          ],
          dateRange: [
            {required: true, message: '请选择时间', trigger: 'blur'}
          ],
          amount: [
            {required: true, message: '请输入金额', trigger: 'blur'}
          ],
          sum: [
            {required: true, message: '请输入数量', trigger: 'blur'}
          ]
        },
        pickerOptions: {
          shortcuts: [{
            text: '当日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              // start.setHours(0,0,0,0)
              // end.setHours(23,59,59,999)
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '当周',
            onClick(picker) {
              picker.$emit('pick', [dayjs().startOf('week').format('YYYY-MM-DD'), dayjs().endOf('week').format('YYYY-MM-DD')]);
            }
          }, {
            text: '当月',
            onClick(picker) {
              const start = new Date();
              start.setDate(1)
              // start.setHours(0,0,0,0)
              const end = new Date(start.getFullYear(),start.getMonth()+1,0);
              // end.setHours(23,59,59,999)
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '当季度',
            onClick(picker) {
              const today = new Date();
              const year = today.getFullYear();
              const quarter = Math.floor((today.getMonth() + 3) / 3);
              const start = new Date(year, (quarter - 1) * 3, 1);
              const end = new Date(year, quarter * 3, 0);
              // end.setHours(23,59,59,999)
              picker.$emit('pick', [start, end]);
            }
          }]
        }
      }
    },
    components: {
    },
    props: {
    },
    watch: {
    },
    created() {
      this.getShopList()
      this.goSearch()
    },
    mounted() {
    },
    methods: {
      getLvFormat(lv) {
        return () => {
          return lv + '%'
        }
      },
      showBox(item) {
        this.dateRange = []
        if(item) {
          this.form = JSON.parse(JSON.stringify(item))
          this.dateRange.push(new Date(Date.parse(this.form.start_time.replace(/-/g,"/"))))
          this.dateRange.push(new Date(Date.parse(this.form.end_time.replace(/-/g,"/"))))
        } else {
          this.form = {
            title: '',
            start_time: '',
            end_time: '',
            type: 1,
            amount: '',
            sum: ''
          }
        }
        this.dialogVisible = true
      },
      getShopList() {
        this.$api.merchant.storeIndex({page_size:1000}, res => {
          if(res.errcode==0) {
            this.shopList = res.data.data
          }
        })
      },
      goSearch() {
        this.showLoading()
        this.$api.statics.staticsOrderProgress({shop_id: this.search.shop_id}, res => {
          this.hideLoading()
          if(res.errcode==0) {
            this.progressList = res.data
          } else {
            this.fail(res.errmsg)
          }
        })
      },
      formatDate(date) {
        if(!date) {
          return '';
        }
        if(typeof date == 'string') {
          return date
        }
        var year = date.getFullYear()
        var month = date.getMonth()+1
        var day = date.getDate()
        return year + '-' + (month>9 ? month : '0' + month) + '-' + (day>9 ? day : '0' + day)
      },
      saveBox() {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            var params = this.form
            params.start_time = this.formatDate(this.dateRange[0])
            params.end_time = this.formatDate(this.dateRange[1])
            if(params.start_time=='' || params.end_time=='') {
              this.fail('请选择日期')
              return false
            }
            this.$api.statics.staticsOrderProgressEdit(params, res => {
              if(res.errcode==0) {
                this.success(res.errmsg)
                this.goSearch()
                this.dialogVisible = false
              } else {
                this.fail(res.errmsg)
              }
            })
          }
        })
      },
      delBox(item) {
        this.$api.statics.staticsOrderProgressDel({id:item.id}, res => {
          if(res.errcode==0) {
            this.success(res.errmsg)
            this.goSearch()
          } else {
            this.fail(res.errmsg)
          }
        })
      }
    }
  }
</script>

<style scoped>
</style>
