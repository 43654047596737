<template>
  <div class="">
    <el-form class="form-inline" :inline="true" size="small">
      <el-form-item label="门店">
        <el-select v-model="search.shop_id" placeholder="请选择">
          <el-option :key="0" label="总部" :value="0"></el-option>
          <el-option
              v-for="item in shopList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时间">
        <el-date-picker
            :clearable="false"
            size="small"
            v-model="dateRange"
            type="daterange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="getTjInfo">搜索</el-button>
      </el-form-item>
    </el-form>
    <h2 class="mg-bt-20" v-if="tjInfo">
      <span class="title mg-rt-10">订货数：{{tjInfo.total.sum}}个，</span>
      <span class="title">订货额：￥{{tjInfo.total.pay_price}}</span>
    </h2>
    <div class="title mg-tp-30">改件订货额（元）</div>
    <div class="echart" id="mychart1" :style="myChartStyle"></div>
    <div class="title mg-tp-30">改件订货数量（件）</div>
    <div class="echart" id="mychart2" :style="myChartStyle"></div>
    <div class="title mg-tp-30">改件TOP5 <el-button type="text" @click="lookTable(1)">查看明细</el-button></div>
<!--    <el-table-->
<!--        :data="tjInfo.top1"-->
<!--        style="width: 80%; margin: 0 auto">-->
<!--      <el-table-column-->
<!--          prop="title"-->
<!--          label="商品名称">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="sum"-->
<!--          label="订购数量"-->
<!--          width="180">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="pay_price"-->
<!--          label="订购金额">-->
<!--      </el-table-column>-->
<!--    </el-table>-->
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="title">普通商品</div>
        <el-table
            :data="tjInfo.top12"
            style="width: 80%; margin: 0 auto">
          <el-table-column
              prop="title"
              label="商品名称">
          </el-table-column>
          <el-table-column
              prop="sum"
              label="订购数量"
              width="180">
          </el-table-column>
          <el-table-column
              prop="pay_price"
              label="订购金额">
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="12">
        <div class="title">补价和售后商品</div>
        <el-table
            :data="tjInfo.top11"
            style="width: 80%; margin: 0 auto">
          <el-table-column
              prop="title"
              label="商品名称">
          </el-table-column>
          <el-table-column
              prop="sum"
              label="订购数量"
              width="180">
          </el-table-column>
          <el-table-column
              prop="pay_price"
              label="订购金额">
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <div class="title mg-tp-30">膜类订货额（元）</div>
    <div class="echart" id="mychart3" :style="myChartStyle"></div>
    <div class="title mg-tp-30">膜类订货额（件）</div>
    <div class="echart" id="mychart4" :style="myChartStyle"></div>
    <div class="title mg-tp-30">膜类TOP5 <el-button type="text" @click="lookTable(2)">查看明细</el-button></div>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="title">普通商品</div>
        <el-table
            :data="tjInfo.top22"
            style="width: 80%; margin: 0 auto">
          <el-table-column
              prop="title"
              label="商品名称">
          </el-table-column>
          <el-table-column
              prop="sum"
              label="订购数量"
              width="180">
          </el-table-column>
          <el-table-column
              prop="pay_price"
              label="订购金额">
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="12">
        <div class="title">补价和售后商品</div>
        <el-table
            :data="tjInfo.top21"
            style="width: 80%; margin: 0 auto">
          <el-table-column
              prop="title"
              label="商品名称">
          </el-table-column>
          <el-table-column
              prop="sum"
              label="订购数量"
              width="180">
          </el-table-column>
          <el-table-column
              prop="pay_price"
              label="订购金额">
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <div class="title mg-tp-30">改件膜类占比</div>
    <div class="echart" id="mychart5" :style="myChartStyle"></div>
    <el-dialog title="订货明细" :visible.sync="dialogVisible" custom-class="dialog">
      <order-goods-log :Time="Time" :shopId="search.shop_id" :type="logType" :startTime="formatDate(this.dateRange[0])" :endTime="formatDate(this.dateRange[1])"></order-goods-log>
    </el-dialog>
  </div>
</template>

<script>
  import dayjs from "dayjs";
  import OrderGoodsLog from "@/components/OrderGoodsLog";
  import * as echarts from "echarts";
  export default {
    data() {
      return {
        dialogVisible: false,
        shopList: [],
        tjInfo: false,
        search: {
          shop_id: 0
        },
        logType: 0,
        Time: '',
        dateRange: ['',''],
        myChart1: false,
        myChart2: false,
        myChart3: false,
        myChart4: false,
        myChart5: false,
        myChartStyle: { float: "left", width: "100%", height: "300px" },
        pickerOptions: {
          shortcuts: [{
            text: '当日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              // start.setHours(0,0,0,0)
              // end.setHours(23,59,59,999)
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '当周',
            onClick(picker) {
              var start = dayjs().startOf('week').add(1,'day').format('YYYY-MM-DD')
              var end = dayjs().endOf('week').add(1,'day').format('YYYY-MM-DD')
              if(new Date().getDay()==0) { //周日前推6天
                start = dayjs().startOf('week').subtract(6,'day').format('YYYY-MM-DD')
                end = dayjs().endOf('week').subtract(6,'day').format('YYYY-MM-DD')
              }
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '当月',
            onClick(picker) {
              const start = new Date();
              start.setDate(1)
              // start.setHours(0,0,0,0)
              const end = new Date(start.getFullYear(),start.getMonth()+1,0);
              // end.setHours(23,59,59,999)
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '当季度',
            onClick(picker) {
              const today = new Date();
              const year = today.getFullYear();
              const quarter = Math.floor((today.getMonth() + 3) / 3);
              const start = new Date(year, (quarter - 1) * 3, 1);
              const end = new Date(year, quarter * 3, 0);
              // end.setHours(23,59,59,999)
              picker.$emit('pick', [start, end]);
            }
          }]
        }
      }
    },
    components: {
      OrderGoodsLog
    },
    props: {
    },
    watch: {
    },
    created() {
      var start = dayjs().startOf('week').add(1,'day').format('YYYY-MM-DD')
      var end = dayjs().endOf('week').add(1,'day').format('YYYY-MM-DD')
      if(new Date().getDay()==0) { //周日前推6天
        start = dayjs().startOf('week').subtract(6,'day').format('YYYY-MM-DD')
        end = dayjs().endOf('week').subtract(6,'day').format('YYYY-MM-DD')
      }

      this.dateRange[0] = start
      this.dateRange[1] = end
      this.getShopList()
      this.getTjInfo();
    },
    mounted() {
    },
    methods: {
      lookTable(type) {
        var _this = this
        this.logType = type
        _this.Time = String(Math.random())
        this.dialogVisible = true
      },
      getShopList() {
        this.$api.merchant.storeIndex({page_size:1000}, res => {
          if(res.errcode==0) {
            this.shopList = res.data.data
          }
        })
      },
      formatDate(date) {
        if(!date) {
          return '';
        }
        if(typeof date == 'string') {
          return date
        }
        var year = date.getFullYear()
        var month = date.getMonth()+1
        var day = date.getDate()
        return year + '-' + (month>9 ? month : '0' + month) + '-' + (day>9 ? day : '0' + day)
      },
      getTjInfo() {
        this.showLoading()
        var params = this.search
        params.start_time = this.formatDate(this.dateRange[0])
        params.end_time = this.formatDate(this.dateRange[1])
        this.$api.statics.staticsOrderIndex(params, res => {
          this.hideLoading()
          if (res.errcode == 0) {
            this.tjInfo = res.data
            this.initEcharts1();
            this.initEcharts2();
            this.initEcharts3();
            this.initEcharts4();
            this.initEcharts5();
          } else {
            this.fail(res.errmsg)
          }
        })
      },
      getOption(x,unit,name,data,color) {
        var option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: '{b}: {c}'+unit
          },
          xAxis: {
            data: x
          },
          yAxis: {
            type: 'value',
            name: '单位（'+unit+'）',
            nameTextStyle: {
              fontWeight: 'bold',
              color: '#666'
            }
          },
          series: [
            {
              name: name,
              type: "bar",
              barWidth: 30,
              data: data,
              itemStyle: {
                normal: {
                  color: color
                }
              },
              label: {
                show: true,
                position: "top",
                textStyle: {
                  fontSize: 16,
                  color: '#333'
                }
              }
            }
          ]
        };
        if(x.length>20) {
          option.dataZoom = [{
            type: 'inside',
            disabled: false,
            start: 0,
            end: 100
          }]
          option.visualMap = {
            type: 'continuous',
            show: true,
            min: 0,
            max: 100,
            dimension: 0,
            inRange: {
              color: ['#d94e5d','#eac736','#50a3ba'].reverse()
            }
          }
        } else {
          option.dataZoom = [{
            type: 'inside',
            disabled: true,
            start: 0,
            end: 100
          }]
          option.visualMap = {
            type: 'continuous',
            show: false,
            min: 0,
            max: 100,
            dimension: 0,
            inRange: {
              color: ['#d94e5d','#eac736','#50a3ba'].reverse()
            }
          }
        }
        return option
      },
      initEcharts1() {
        var option = this.getOption(this.tjInfo.x,'元','销售额',this.tjInfo.data1,'orange')
        this.myChart1 = echarts.init(document.getElementById("mychart1"));
        this.myChart1.setOption(option);
        window.addEventListener("resize", () => {
          this.myChart1.resize();
        });
      },
      initEcharts2() {
        var option = this.getOption(this.tjInfo.x,'件','销售数',this.tjInfo.data2,'#FF9A6E')
        this.myChart2 = echarts.init(document.getElementById("mychart2"));
        this.myChart2.setOption(option);
        window.addEventListener("resize", () => {
          this.myChart2.resize();
        });
      },
      initEcharts3() {
        var option = this.getOption(this.tjInfo.x,'元','销售额',this.tjInfo.data3,'#91C7AE')
        this.myChart3 = echarts.init(document.getElementById("mychart3"));
        this.myChart3.setOption(option);
        window.addEventListener("resize", () => {
          this.myChart3.resize();
        });
      },
      initEcharts4() {
        var option = this.getOption(this.tjInfo.x,'件','销售数',this.tjInfo.data4)
        this.myChart4 = echarts.init(document.getElementById("mychart4"));
        this.myChart4.setOption(option);
        window.addEventListener("resize", () => {
          this.myChart4.resize();
        });
      },
      initEcharts5() {
        const option = {
          legend: {
            data: ['金额比例（元）', '数量比例（件）'],
            // orient: 'vertical',
            left: 'center'
          },
          tooltip: {},
          series: [{
            name: '金额比例（元）',
            type: 'pie',
            radius: '50%',
            center: ['30%', '50%'],
            data: this.tjInfo.pie1
          },{
            name: '数量比例（件）',
            type: 'pie',
            radius: '50%',
            center: ['60%', '50%'],
            data: this.tjInfo.pie2
          }]
        };
        this.myChart5 = echarts.init(document.getElementById("mychart5"));
        this.myChart5.setOption(option);
        window.addEventListener("resize", () => {
          this.myChart5.resize();
        });
      },
    }
  }
</script>

<style scoped>
.gdtable tr:last-child {
  border-bottom: unset !important;
}
.title {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #666;
  font-family: 楷体;
  text-align: center;
}
.el-form-item {
  margin-bottom: 10px;
}
</style>
