<template>
  <div class="">
    <el-form class="form-inline" :inline="true" size="small">
      <el-form-item label="门店">
        <el-select v-model="search.shop_id" placeholder="请选择">
          <el-option :key="0" label="总部" :value="0"></el-option>
          <el-option
              v-for="item in shopList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="goSearch">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="title mg-tp-30">总订货金额（元） 对比曲线</div>
    <div class="echart" id="mychart1" :style="myChartStyle"></div>
    <div class="title mg-tp-30">改件订货额（元） 对比曲线</div>
    <div class="echart" id="mychart2" :style="myChartStyle"></div>
    <div class="title mg-tp-30">改件数量（件） 对比曲线</div>
    <div class="echart" id="mychart3" :style="myChartStyle"></div>
    <div class="title mg-tp-30">膜类订货额（元） 对比曲线</div>
    <div class="echart" id="mychart4" :style="myChartStyle"></div>
    <div class="title mg-tp-30">膜类数量（件） 对比曲线</div>
    <div class="echart" id="mychart5" :style="myChartStyle"></div>
    <div class="title mg-tp-30">改件订货量前5名</div>
    <el-row :gutter="10">
      <el-col :span="12">
        <div class="title">本月前5名</div>
        <el-table
            :data="dataInfo.top11"
            style="width: 100%;">
          <el-table-column
              prop="title"
              label="商品名称">
          </el-table-column>
          <el-table-column
              prop="sum"
              label="订购数量（件）"
              width="180">
          </el-table-column>
          <el-table-column
              prop="pay_price"
              label="订购金额（元）"
              width="180">
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="12">
        <div class="title">上月前5名</div>
        <el-table
            :data="dataInfo.top12"
            style="width: 100%; margin: 0 auto">
          <el-table-column
              prop="title"
              label="商品名称">
          </el-table-column>
          <el-table-column
              prop="sum"
              label="订购数量（件）"
              width="180">
          </el-table-column>
          <el-table-column
              prop="pay_price"
              label="订购金额（元）"
              width="180">
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <div class="title mg-tp-30">膜类订货量前5名</div>
    <el-row :gutter="10">
      <el-col :span="12">
        <div class="title">本月前5名</div>
        <el-table
            :data="dataInfo.top21"
            style="width: 100%; margin: 0 auto">
          <el-table-column
              prop="title"
              label="商品名称">
          </el-table-column>
          <el-table-column
              prop="sum"
              label="订购数量（件）"
              width="180">
          </el-table-column>
          <el-table-column
              prop="pay_price"
              label="订购金额（元）"
              width="180">
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="12">
        <div class="title">上月前5名</div>
        <el-table
            :data="dataInfo.top22"
            style="width: 100%; margin: 0 auto">
          <el-table-column
              prop="title"
              label="商品名称">
          </el-table-column>
          <el-table-column
              prop="sum"
              label="订购数量（件）"
              width="180">
          </el-table-column>
          <el-table-column
              prop="pay_price"
              label="订购金额（元）"
              width="180">
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <div class="title mg-tp-30">改件膜类占比</div>
    <div class="echart" id="mychart10" :style="myChartStyle"></div>
  </div>
</template>

<script>
  import * as echarts from "echarts";

  export default {
    data() {
      return {
        dialogVisible: false,
        formLabelWidth: '60px',
        source_domain: this.config.SOURCE_DOMAIN,
        myChartStyle: { float: "left", width: "100%", height: "300px" },
        myChart1: false,
        myChart2: false,
        myChart3: false,
        myChart4: false,
        myChart5: false,
        myChart10: false,
        shopList: [],
        dataInfo: false,
        search: {
          shop_id: 0
        }
      }
    },
    components: {
    },
    props: {
    },
    watch: {
    },
    created() {
      this.getShopList()
      this.goSearch()
    },
    mounted() {
    },
    methods: {
      getShopList() {
        this.$api.merchant.storeIndex({page_size:1000}, res => {
          if(res.errcode==0) {
            this.shopList = res.data.data
          }
        })
      },
      goSearch() {
        this.showLoading()
        this.$api.statics.staticsGoOver({shop_id: this.search.shop_id}, res => {
          this.hideLoading()
          if(res.errcode==0) {
            this.dataInfo = res.data
            this.initEcharts1()
            this.initEcharts2()
            this.initEcharts3()
            this.initEcharts4()
            this.initEcharts5()
            this.initEcharts10()
          } else {
            this.fail(res.errmsg)
          }
        })
      },
      formatDate(date) {
        if(!date) {
          return '';
        }
        if(typeof date == 'string') {
          return date
        }
        var year = date.getFullYear()
        var month = date.getMonth()+1
        var day = date.getDate()
        return year + '-' + (month>9 ? month : '0' + month) + '-' + (day>9 ? day : '0' + day)
      },
      getOption(data1,data2) {
        var options = {
          tooltip: {},
          legend: {
            data: ['现在', '过去']
          },
          xAxis: {
            data: ['对比昨日', '对比上周', '对比上月', '对比上季度']
          },
          yAxis: {},
          series: [
            {
              name: '现在',
              type: 'line',
              data: data1
            },
            {
              name: '过去',
              type: 'line',
              data: data2
            }
          ]
        };
        return options
      },
      initEcharts1() {
        var options = this.getOption(this.dataInfo.data11,this.dataInfo.data12)
        this.myChart1 = echarts.init(document.getElementById("mychart1"));
        this.myChart1.setOption(options);
        window.addEventListener("resize", () => {
          this.myChart1.resize();
        });
      },
      initEcharts2() {
        var options = this.getOption(this.dataInfo.data21,this.dataInfo.data22)
        this.myChart2 = echarts.init(document.getElementById("mychart2"));
        this.myChart2.setOption(options);
        window.addEventListener("resize", () => {
          this.myChart2.resize();
        });
      },
      initEcharts3() {
        var options = this.getOption(this.dataInfo.data31,this.dataInfo.data32)
        this.myChart3 = echarts.init(document.getElementById("mychart3"));
        this.myChart3.setOption(options);
        window.addEventListener("resize", () => {
          this.myChart3.resize();
        });
      },
      initEcharts4() {
        var options = this.getOption(this.dataInfo.data41,this.dataInfo.data42)
        this.myChart4 = echarts.init(document.getElementById("mychart4"));
        this.myChart4.setOption(options);
        window.addEventListener("resize", () => {
          this.myChart4.resize();
        });
      },
      initEcharts5() {
        var options = this.getOption(this.dataInfo.data51,this.dataInfo.data52)
        this.myChart5 = echarts.init(document.getElementById("mychart5"));
        this.myChart5.setOption(options);
        window.addEventListener("resize", () => {
          this.myChart5.resize();
        });
      },
      initEcharts10() {
        const option = {
          legend: {
            data: ['对比昨日','对比上周','对比上月','对比上季度'],
            // orient: 'vertical',
            left: 'center'
          },
          tooltip: {},
          series: [{
            name: '对比昨日',
            type: 'pie',
            radius: '50%',
            center: ['10%', '50%'],
            data: this.dataInfo.pie1
          },{
            name: '对比上周',
            type: 'pie',
            radius: '50%',
            center: ['35%', '50%'],
            data: this.dataInfo.pie2
          },{
            name: '对比上月',
            type: 'pie',
            radius: '50%',
            center: ['60%', '50%'],
            data: this.dataInfo.pie3
          },{
            name: '对比上季度',
            type: 'pie',
            radius: '50%',
            center: ['85%', '50%'],
            data: this.dataInfo.pie4
          }]
        };
        this.myChart10 = echarts.init(document.getElementById("mychart10"));
        this.myChart10.setOption(option);
        window.addEventListener("resize", () => {
          this.myChart10.resize();
        });
      }
    }
  }
</script>

<style scoped>
.title {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #666;
  font-family: 楷体;
  text-align: center;
}
</style>
