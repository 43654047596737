<template>
  <div class="main-box">
    <el-tabs type="card" @tab-click="goSearch" v-model="menuType">
      <el-tab-pane label="订货数据" name="1"></el-tab-pane>
      <el-tab-pane label="对比过去" name="2"></el-tab-pane>
      <el-tab-pane label="未来进度" name="3"></el-tab-pane>
      <el-tab-pane label="总部汇总" name="4"></el-tab-pane>
    </el-tabs>
    <div v-if="menuType==1">
      <order-goods-box></order-goods-box>
    </div>
    <div v-if="menuType==2">
      <go-over-box></go-over-box>
    </div>
    <div v-if="menuType==3">
      <future-box></future-box>
    </div>
    <div v-if="menuType==4">
      <el-form class="form-inline" :inline="true" size="small">
        <el-form-item label="时间">
          <el-date-picker class="mg-rt-10"
              v-model="tjForm.start_time"
              type="date"
              placeholder="选择开始日期">
          </el-date-picker>
          <el-date-picker
              v-model="tjForm.end_time"
              type="date"
              placeholder="选择结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getTjSummery">搜索</el-button>
        </el-form-item>
      </el-form>
      <div v-if="zongInfo">
        <div class="mg-tp-20"><span class="title mg-rt-10">商品总金额：￥{{zongInfo.pay_price}}</span></div>
        <div class="mg-tp-20"><span class="title mg-rt-10">总付款金额：￥{{zongInfo.order_pay_price}}</span></div>
        <div class="mg-tp-20"><span class="title">总订货数量：{{zongInfo.sum}}</span></div>
        <div class="mg-tp-20"><span class="title">订货总成本：￥{{zongInfo.cost_price}}</span></div>
        <div class="mg-tp-20"><span class="title">订货总利润：￥{{zongInfo.profit}}</span></div>
      </div>
    </div>
  </div>
</template>
<script>

import * as echarts from "echarts";
import FutureBox from "@/components/FutureBox";
import OrderGoodsBox from "@/components/OrderGoodsBox";
import GoOverBox from "@/components/GoOverBox";
export default {
  components: {
    FutureBox,
    OrderGoodsBox,
    GoOverBox
  },
  data() {
    return {
      dialogVisible: false,
      source_domain: this.config.SOURCE_DOMAIN,
      menuType: '1',
      myChartStyle: { float: "left", width: "100%", height: "300px" },
      tjForm: {
        start_time: '',
        end_time: ''
      },
      zongInfo: false
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {},
  methods: {
    goSearch() {
      if(this.menuType==4) {
        this.getTjSummery()
      }
    },
    formatDate(date) {
      if(!date) {
        return '';
      }
      if(typeof date == 'string') {
        return date
      }
      var year = date.getFullYear()
      var month = date.getMonth()+1
      var day = date.getDate()
      return year + '-' + (month>9 ? month : '0' + month) + '-' + (day>9 ? day : '0' + day)
    },
    getTjSummery() {
      var params = this.tjForm
      params.start_time = this.formatDate(params.start_time)
      params.end_time = this.formatDate(params.end_time)
      this.$api.statics.staticsOrderSummary(params, res => {
        if(res.errcode==0) {
          this.zongInfo = res.data
        } else {
          this.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
.gdtable tr:last-child {
  border-bottom: unset !important;
}
.title {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #666;
  font-family: 楷体;
  text-align: center;
}
.el-form-item {
  margin-bottom: 10px;
}
</style>
