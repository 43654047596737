<template>
  <div class="">
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="title"
          label="商品名称">
      </el-table-column>
      <el-table-column
          prop="store_title"
          label="门店"
          align="center"
          width="200">
      </el-table-column>
      <el-table-column
          prop="quantity"
          label="数量"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="pay_price"
          label="总金额"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="下单时间"
          align="center"
          width="140">
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="search.current_page"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        source_domain: this.config.SOURCE_DOMAIN,
        search: {
          current_page: 1,
          total: 0,
          limit: 10,
          keywords: '',
          status: '',
          tjstatus: '',
          supplier: '',
          menu: ''
        },
        tableData: [],
      }
    },
    components: {
    },
    props: {
      shopId: Number,
      startTime: String,
      endTime: String,
      type: Number,
      Time: String
    },
    watch: {
      'Time': {
        handler (newValue, oldValue) {
          this.search.current_page = 1
          this.getList()
        },
        immediate: true,
        deep: true
      }
    },
    created() {
    },
    mounted() {
    },
    methods: {
      getList() {
        var that = this
        that.showLoading()
        var param = {
          page: that.search.current_page,
          page_size: that.search.limit,
          shop_id: this.shopId,
          start_time: this.startTime,
          end_time: this.endTime,
          type: this.type
        }
        this.$api.statics.staticsOrderLog(param, function (res) {
          that.hideLoading()
          if (res.errcode == 0) {
            that.tableData = res.data.data
            that.search.current_page = res.data.current_page
            that.search.total = res.data.total
          } else {
            that.fail(res.errmsg)
          }
        })
      },
      handleSearch() {
        this.search.current_page = 1
        this.getList()
      },
      handleSelectionChange(rows) {
      },
      handleCurrentChange(val) {
        this.search.current_page = val
        this.getList()
      },
      handleSizeChange(val) {
        this.search.current_page = 1
        this.search.limit = val
        this.getList()
      }
    }
  }
</script>

<style scoped>
</style>
